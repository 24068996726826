import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, UncontrolledTooltip } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import AccountClockIcon from 'mdi-react/AccountClockIcon';
import FilterMenuIcon from 'mdi-react/FilterMenuIcon';
import AccountCashIcon from 'mdi-react/AccountCashIcon';
import AccountArrowLeftOutlineIcon from 'mdi-react/AccountArrowLeftOutlineIcon';
import AccountOffIcon from 'mdi-react/AccountOffIcon';
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';
import { useTranslation } from 'react-i18next';
import {
  getAllLeadsFetchingActions,
  getAllLeadsSuccess, getLeadCountsFetching,
} from '../../../redux/actions/leadsPageActions';
import DataReactTable from '../../Tables/DataTable/components/DataReactTable';
import { columns } from './LeadsDataColumns';
import './LeadsPage.css';
import {
  getLeadManagerId,
  getLeadManagerIdAndStatus,
  getLeadStatus,
  postContractLead,
  postKillClient,
  postProspectLead,
  postQualifyLead,
  postQuoteLead,
} from '../../../utils/api';
import QualifyForm from './QualifyForm/QualifyForm';
import ProspectForm from './ProspectForm/ProspectForm';
import QuoteForm from './QuoteForm/QuoteForm';
import ContractForm from './ContractForm/ContractForm';
import KillForm from './KillForm/KillForm';
import ConfirmModal from './KillForm/ConfirmModal';
import { handleFile } from '../../../utils/helpers';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import { reset } from 'redux-form';


const LeadsPage = () => {
  const authUser = useSelector(state => state.auth.user);
  const { leads, loading, errors, totalRecords, leadCounts } = useSelector((state) => state.leads);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const parsedQuery = parse(location.search);
  const [activeManagerTab, setActiveManagerTab] = useState(parsedQuery?.manager_id || 'all');
  const [activeStatusTab, setActiveStatusTab] = useState(parsedQuery?.status || 'lead');
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [mesErrors, setMesErrors] = useState([]);
  const [leadId, setLeadId] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [checkStatus, setCheckStatus] = useState('');
  const [openKillModal, setOpenKillModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState('');
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);
  const [query, setQuery] = useState({ start: 0, limit: 10, search_query: '' });


  const toggle = (val) => {
    setCheckStatus(val.status === checkStatus ? '' : val.status);
    setLeadId(val.id);
  };

  const toggleKill = (val) => {
    setOpenKillModal(!openKillModal);
    setLeadId(val.id);
  };

  useEffect(() => {
    dispatch(getLeadCountsFetching());
  }, [dispatch]);

  const getFilteredLeads = useCallback(updatedQuery => {
    const newUpdatedQuery = { ...updatedQuery, status: "", manager_id: authUser.userId };
    if (activeManagerTab === authUser.userId && activeStatusTab === 'all') {
      getLeadManagerId(authUser.userId, newUpdatedQuery, authUser.accessToken).then(({ data }) =>
        dispatch(getAllLeadsSuccess(data.data))
      );
      return;
    }
    if (activeManagerTab === 'all' && activeStatusTab === 'all') {
      const newUpdatedQuery = { ...updatedQuery, status: "" };
      dispatch(getAllLeadsFetchingActions(newUpdatedQuery));
      return;
    }
    if (activeManagerTab === authUser.userId) {
      const newUpdatedQuery = { ...updatedQuery, manager_id: authUser.userId };
      getLeadManagerIdAndStatus(authUser.userId, activeStatusTab, newUpdatedQuery, authUser.accessToken).then(({ data }) =>
        dispatch(getAllLeadsSuccess(data.data))
      );
      return;
    }
    getLeadStatus(updatedQuery, authUser.accessToken).then(({ data }) =>
      dispatch(getAllLeadsSuccess(data.data))
    );
  }, [authUser.userId, dispatch, activeManagerTab, activeStatusTab, authUser.accessToken]);


  const handleSubmitQualifyLead = async (value) => {
    const result = await handleFile(value.comment_file?.file);

    postQualifyLead(leadId, value.description, value.comment, result, authUser.accessToken).then(
      (res) => {
        !res.data.success
          ? setMesErrors(res.data.errors)
          : setRefreshCounter(refreshCounter + 1);
      },
    );

    toggle({ status: '' });
  };

  const handleSubmitProspectLead = async (value) => {
    const result = await handleFile(value.comment_file?.file);

    postProspectLead(
      leadId,
      value.budget?.replace(/[$,]+/g, ''),
      value.start_date,
      value.comment,
      result,
      authUser.accessToken,
    ).then((res) => {
      !res.data.success
        ? setMesErrors(res.data.errors)
        : setRefreshCounter(refreshCounter + 1);
    });

    toggle({ status: '' });
  };

  const handleSubmitQuoteLead = async (value) => {
    const result = await handleFile(value.comment_file?.file);

    postQuoteLead(leadId, value.estimate, value.comment, result, authUser.accessToken).then((res) => {
      !res.data.success
        ? setMesErrors(res.data.errors)
        : setRefreshCounter(refreshCounter + 1);
    });
    toggle({ status: '' });
  };

  const handleSubmitContractLead = async (value) => {
    const result = await handleFile(value.comment_file?.file);

    postContractLead(leadId, value.select?.id, value.comment, result, authUser.accessToken).then(
      (res) => {
        !res.data.success
          ? setMesErrors(res.data.errors)
          : setRefreshCounter(refreshCounter + 1);
      },
    );
    toggle({ status: '' });
  };

  const handleConfirmKillLead = ({ comment, file }) => {
    postKillClient(leadId, comment, file, authUser.accessToken).then((res) => {
      !res.data.success
        ? setMesErrors(res.data.errors)
        : setRefreshCounter(refreshCounter + 1);
    });

    setOpenConfirmModal('');
    setOpenKillModal(false);
    dispatch(reset('kill_form'));
  };

  const handleSubmitKillLead = async (value) => {
    const file = await handleFile(value.comment_file?.file);
    setOpenConfirmModal({ ...value, file });
  };

  const handleViewLead = (id) => {
    navigate(`${id}`, {
      state: {
        prevQuery: [{
          query: location.search,
          path: location.pathname,
        }]
      }
    });
  };

  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit,
      status: activeStatusTab,
      manager_id: activeManagerTab !== 'all' ? authUser.userId : ''
    };

    navigate(`${location.pathname}?${stringify(updatedQuery, { arrayFormat: 'comma' })}`);
    getFilteredLeads(updatedQuery);
  }, [currentPage, pageLimit, activeManagerTab, activeStatusTab, refreshCounter, getFilteredLeads, query, location.pathname, navigate, authUser.userId]);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const getSearchValue = _.debounce((value) => {
    if (value === "0") return;

    setQuery({ ...query, search_query: value });
  }, 600)

  return (
    <Container>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <Card >
            <CardBody>
              <div className='top-lead-tabs tabs tabs--bordered-top'>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={`custom-tab ${activeManagerTab === 'all' ? 'active' : ''}`}
                      onClick={() => setActiveManagerTab('all')}
                    >
                      All {activeManagerTab === 'all' && <>
                      <HelpCircleOutlineIcon size={"14px"} className={"ml-1 question-icon question-icon"} id="all" />
                      <UncontrolledTooltip placement="top" target="all">
                        See the complete list of All leads of the system added by all managers
                      </UncontrolledTooltip></>}
                      {leadCounts?.all?.total > 0 && <span className="count">{leadCounts?.all?.total}</span>}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`custom-tab ${activeManagerTab === `${authUser.userId}` ? 'active' : ''}`}
                      onClick={() => setActiveManagerTab(`${authUser.userId}`)}
                    >
                      Your Leads {activeManagerTab === `${authUser.userId}` && <>
                      <HelpCircleOutlineIcon size={"14px"} className={"ml-1 question-icon"} id="your_leads" />
                      <UncontrolledTooltip placement="top" target="your_leads">
                        Filter the list to show only your leads.
                      </UncontrolledTooltip></>}
                      {leadCounts?.all?.my > 0 && (<span className="count">{leadCounts?.all?.my}</span>)}
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>


              <div className='mt-4 tabs lead-tabs tabs--justify tabs--bordered-top'>
                <Nav tabs className="">
                  <NavItem>
                    <NavLink
                      className={`${parsedQuery?.manager_id === `${authUser?.userId}` ? leadCounts?.new?.my <= 0 ? 'below_zero' : 'above_zero' : leadCounts?.new?.total <= 0 ? 'below_zero' : 'above_zero'} ${activeStatusTab === 'lead' ? 'active' : ''}`}
                      onClick={() => setActiveStatusTab('lead')}
                      style={{ color: `${activeStatusTab === 'lead' ? 'purple' : '#646777'}`, borderTopColor: `${activeStatusTab === 'lead' ? 'purple' : ''}` }}
                    >
                      New Leads {parsedQuery?.manager_id === `${authUser.userId}` ? leadCounts?.new?.my > 0 && (<span className="count">{leadCounts?.new.my}</span>) : leadCounts?.new?.total > 0 && <span className="count">{leadCounts?.new?.total}</span>}
                      {activeStatusTab === 'lead' && <>
                        <HelpCircleOutlineIcon size={"14px"} className={"ml-1 question-icon"} id="new_leads" />
                        <UncontrolledTooltip placement="top" target="new_leads">
                          Filter the list to display only newly added leads.
                        </UncontrolledTooltip>
                      </>}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${parsedQuery?.manager_id === `${authUser.userId}` ? leadCounts?.qualified?.my <= 0 ? 'below_zero' : 'above_zero' : leadCounts?.qualified?.total <= 0 ? 'below_zero' : 'above_zero'} ${activeStatusTab === 'qualified_lead' ? 'active' : ''}`}
                      onClick={() => setActiveStatusTab('qualified_lead')}
                      style={{ color: `${activeStatusTab === 'qualified_lead' ? '#f46f25' : '#646777'}`, borderTopColor: `${activeStatusTab === 'qualified_lead' ? '#f46f25' : ''}` }}
                    >
                      Qualified Leads {parsedQuery?.manager_id === `${authUser.userId}` ?
                      leadCounts?.qualified?.my > 0 && (<span className="count">{leadCounts?.qualified.my}</span>) :
                      leadCounts?.qualified?.total > 0 && <span className="count">{leadCounts?.qualified?.total}</span>
                    }
                      {activeStatusTab === 'qualified_lead' && <>
                        <HelpCircleOutlineIcon size={"14px"} className={"ml-1 question-icon"} id="qualified" />
                        <UncontrolledTooltip placement="top" target="qualified">
                          Filter the list to display leads which are verified and are ready for the next sales step.
                        </UncontrolledTooltip></>}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${parsedQuery?.manager_id === `${authUser.userId}` ? leadCounts?.prospect?.my <= 0 ? 'below_zero' : 'above_zero' : leadCounts?.prospect?.total <= 0 ? 'below_zero' : 'above_zero'} ${activeStatusTab === 'prospect_lead' ? 'active' : ''}`}
                      onClick={() => setActiveStatusTab('prospect_lead')}
                      style={{ color: `${activeStatusTab === 'prospect_lead' ? 'orange' : '#646777'}`, borderTopColor: `${activeStatusTab === 'prospect_lead' ? 'orange' : ''}` }}
                    >
                      Prospect Leads {parsedQuery?.manager_id === `${authUser.userId}` ?
                      leadCounts?.prospect?.my > 0 && (<span className="count">{leadCounts?.prospect?.my}</span>) :
                      leadCounts?.prospect?.total > 0 && <span className="count">{leadCounts?.prospect?.total}</span>
                    }
                      {activeStatusTab === 'prospect_lead' && <>
                        <HelpCircleOutlineIcon size={"14px"} className={"ml-1 question-icon"} id="prospect" />
                        <UncontrolledTooltip placement="top" target="prospect">
                          Filter the list to display early-stage leads that may turn into opportunities.
                        </UncontrolledTooltip></>}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${parsedQuery?.manager_id === `${authUser.userId}` ? leadCounts?.quoted?.my <= 0 ? 'below_zero' : 'above_zero' : leadCounts?.quoted?.total <= 0 ? 'below_zero' : 'above_zero'} ${activeStatusTab === 'quoted_client' ? 'active' : ''}`}
                      onClick={() => setActiveStatusTab('quoted_client')}
                      style={{ color: `${activeStatusTab === 'quoted_client' ? '#ffea48' : '#646777'}`, borderTopColor: `${activeStatusTab === 'quoted_client' ? '#ffea48' : ''}` }}
                    >
                      Quoted Clients {parsedQuery?.manager_id === `${authUser.userId}` ?
                      leadCounts?.quoted?.my > 0 && (<span className="count">{leadCounts?.quoted?.my}</span>) :
                      leadCounts?.quoted?.total > 0 && <span className="count">{leadCounts?.quoted?.total}</span>
                    }
                      {activeStatusTab === 'quoted_client' && <>
                        <HelpCircleOutlineIcon size={"14px"} className={"ml-1 question-icon"} id="quoted" />
                        <UncontrolledTooltip placement="top" target="quoted">
                          Filter the list to display potential clients who are received your pricing details.
                        </UncontrolledTooltip></>}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${parsedQuery?.manager_id === `${authUser.userId}` ? leadCounts?.client?.my <= 0 ? 'below_zero' : 'above_zero' : leadCounts?.client?.total <= 0 ? 'below_zero' : 'above_zero'} ${activeStatusTab === 'client' ? 'active' : ''}`}
                      onClick={() => setActiveStatusTab('client')}
                      style={{ color: `${activeStatusTab === 'client' ? 'green' : '#646777'}`, borderTopColor: `${activeStatusTab === 'client' ? 'green' : ''}` }}
                    >
                      Clients {parsedQuery?.manager_id === `${authUser.userId}` ?
                      leadCounts?.client?.my > 0 && (<span className="count">{leadCounts?.client?.my}</span>) :
                      leadCounts?.client?.total > 0 && <span className="count">{leadCounts?.client?.total}</span>
                    }
                      {activeStatusTab === 'client' && <>
                        <HelpCircleOutlineIcon size={"14px"} className={"ml-1 question-icon"} id="client" />
                        <UncontrolledTooltip placement="top" target="client">
                          Filter the list to display your confirmed customers in one place.
                        </UncontrolledTooltip></>}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${parsedQuery?.manager_id === `${authUser.userId}` ? leadCounts?.dead?.my <= 0 ? 'below_zero' : 'above_zero' : leadCounts?.dead?.total <= 0 ? 'below_zero' : 'above_zero'} ${activeStatusTab === 'dead_client' ? 'active' : ''}`}
                      onClick={() => setActiveStatusTab('dead_client')}
                      style={{ color: `${activeStatusTab === 'dead_client' ? '#46d8d8' : '#646777'}`, borderTopColor: `${activeStatusTab === 'dead_client' ? '#46d8d8' : ''}` }}
                    >
                      Dead Clients {parsedQuery?.manager_id === `${authUser.userId}` ?
                      leadCounts?.dead?.my > 0 && (<span className="count">{leadCounts?.dead?.my}</span>) :
                      leadCounts?.dead?.total > 0 && <span className="count">{leadCounts?.dead?.total}</span>
                    }
                      {activeStatusTab === 'dead_client' && <>
                        <HelpCircleOutlineIcon size={"14px"} className={"ml-1 question-icon"} id="dead" />
                        <UncontrolledTooltip placement="top" target="dead">
                          Filter the list to display clients who didn't proceed with your services.
                        </UncontrolledTooltip></>}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${parsedQuery?.manager_id === `${authUser.userId}` ? leadCounts?.all?.my <= 0 ? 'below_zero' : 'above_zero' : leadCounts?.all?.total <= 0 ? 'below_zero' : 'above_zero'} ${activeStatusTab === 'all' ? 'active' : ''}`}
                      onClick={() => setActiveStatusTab('all')}>
                      All Leads {parsedQuery?.manager_id === `${authUser.userId}` ?
                      leadCounts?.all?.my > 0 && (<span className="count">{leadCounts?.all?.my}</span>) :
                      leadCounts?.all?.total > 0 && <span className="count">{leadCounts?.all?.total}</span>
                    }
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <ConfirmModal
                modal={openConfirmModal}
                toggle={() => setOpenConfirmModal('')}
                onNext={handleConfirmKillLead}
                message={t('leads.kill_form.confirm')}
                color="danger"
              />
              <QualifyForm
                modal={checkStatus === 'lead' || checkStatus === 'dead_client'}
                toggle={toggle}
                onSubmit={handleSubmitQualifyLead}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                leadId={leadId}
              />
              <ProspectForm
                modal={checkStatus === 'qualified_lead'}
                toggle={toggle}
                onSubmit={handleSubmitProspectLead}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                leadId={leadId}
              />
              <QuoteForm
                modal={checkStatus === 'prospect_lead'}
                toggle={toggle}
                onSubmit={handleSubmitQuoteLead}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                leadId={leadId}
              />
              <ContractForm
                modal={(checkStatus === 'quoted_client')}
                toggle={toggle}
                onSubmit={handleSubmitContractLead}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
              />
              <KillForm
                modal={openKillModal}
                toggle={toggleKill}
                onSubmit={handleSubmitKillLead}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
              />


              <DataReactTable
                tableName="Leads"
                sortTable
                loading={loading}
                search={true}
                classList={"mt-3"}
                searchValue={getSearchValue}
                pagination={{
                  onChangePageSize: handleChangePageSize,
                  onChangePage: handleChangePage,
                  currentPage,
                  totalRecords,
                  pageLimit,
                }}
                tableOverrodeConfig={{ manualPageSize: [50, 40, 30, 20, 10] }}
                emptyStateMessage={t('empty_states.leads')}
                reactTableData={{
                  tableHeaderData: columns,
                  tableRowsData: leads?.map((c) => {
                    return {
                      ...c,
                      id: `${c.id}`,
                      manager: `${c.manager.name}`,
                      source: `${c?.source}`,
                      actions: (
                        <>
                          {c.status !== 'quoted_client' ? (
                            <>
                              <UncontrolledTooltip placement="top" target="eyeIcon">
                                {t('leads.tooltip.view')}
                              </UncontrolledTooltip>
                              <button
                                style={{ border: 0, background: 'transparent' }}
                                onClick={() => handleViewLead(c.id)}
                              >
                                <EyeIcon id="eyeIcon" color="#fff" size={18} />
                              </button>
                            </>
                          ) : null}

                          {c.status === 'lead' ? (
                            <>
                              <UncontrolledTooltip placement="top" target="accountClockIcon">
                                {t('leads.tooltip.qualify')}
                              </UncontrolledTooltip>
                              <button
                                style={{ border: 0, background: 'transparent' }}
                              >
                                <AccountClockIcon
                                  color="#fff"
                                  size={18}
                                  id="accountClockIcon"
                                  data-testid="qualify-button"
                                  onClick={() => toggle(c)}
                                />
                              </button>
                            </>
                          ) : null}

                          {c.status === 'qualified_lead' ? (
                            <>
                              <UncontrolledTooltip placement="top" target="filterMenuIcon">
                                {t('leads.tooltip.prospect')}
                              </UncontrolledTooltip>
                              <button
                                style={{ border: 0, background: 'transparent' }}
                              >
                                <FilterMenuIcon
                                  color="#fff"
                                  size={18}
                                  id="filterMenuIcon"
                                  onClick={() => toggle(c)}
                                />
                              </button>

                              <UncontrolledTooltip placement="top" target="accountCashIcon">
                                {t('leads.tooltip.quote')}
                              </UncontrolledTooltip>
                              <button
                                style={{ border: 0, background: 'transparent' }}
                              >
                                <AccountCashIcon
                                  color="#fff"
                                  size={18}
                                  id="accountCashIcon"
                                  onClick={() => toggle({status: "prospect_lead", id: c.id})}
                                />
                              </button>

                              <UncontrolledTooltip placement="top" target="accountArrowIcon">
                                {t('leads.tooltip.contract')}
                              </UncontrolledTooltip>
                              <button
                                style={{ border: 0, background: 'transparent' }}
                              >
                                <AccountArrowLeftOutlineIcon
                                  color="#fff"
                                  size={18}
                                  id="accountArrowIcon"
                                  onClick={() => toggle({status: "quoted_client", id: c.id})}
                                />
                              </button>
                            </>
                          ) : null}

                          {c.status === 'prospect_lead' ? (
                            <>
                              <UncontrolledTooltip placement="top" target="accountCashIcon">
                                {t('leads.tooltip.quote')}
                              </UncontrolledTooltip>
                              <button
                                style={{ border: 0, background: 'transparent' }}
                              >
                                <AccountCashIcon
                                  color="#fff"
                                  size={18}
                                  id="accountCashIcon"
                                  onClick={() => toggle(c)}
                                />
                              </button>

                              <UncontrolledTooltip placement="top" target="accountArrowIcon">
                                {t('leads.tooltip.contract')}
                              </UncontrolledTooltip>
                              <button
                                style={{ border: 0, background: 'transparent' }}
                              >
                                <AccountArrowLeftOutlineIcon
                                  color="#fff"
                                  size={18}
                                  id="accountArrowIcon"
                                  onClick={() => toggle({status: "quoted_client", id: c.id})}
                                />
                              </button>
                            </>
                          ) : null}

                          {c.status === 'quoted_client' ? (
                            <>
                              <UncontrolledTooltip placement="top" target="eyeIcon">
                                {t('leads.tooltip.view')}
                              </UncontrolledTooltip>
                              <button
                                style={{ border: 0, background: 'transparent' }}
                              >
                                <EyeIcon
                                  onClick={() => handleViewLead(c.id)}
                                  color="#000"
                                  id="eyeIcon"
                                  size={18}
                                />
                              </button>

                              <UncontrolledTooltip placement="top" target="accountArrowIcon">
                                {t('leads.tooltip.contract')}
                              </UncontrolledTooltip>
                              <button
                                style={{ border: 0, background: 'transparent' }}
                              >
                                <AccountArrowLeftOutlineIcon
                                  color="#000"
                                  size={18}
                                  id="accountArrowIcon"
                                  onClick={() => toggle(c)}
                                />
                              </button>

                              <UncontrolledTooltip placement="top" target="killIcon">
                                {t('leads.tooltip.kill')}
                              </UncontrolledTooltip>
                              <button
                                style={{ border: 0, background: 'transparent' }}
                              >
                                <AccountOffIcon
                                  color="#000"
                                  size={18}
                                  id="killIcon"
                                  onClick={() => toggleKill(c)}
                                />
                              </button>
                            </>
                          ) : null}

                          {c.status === 'dead_client' ? (
                            <>
                              <UncontrolledTooltip placement="top" target="accountClockIcon">
                                {t('leads.tooltip.qualify')}
                              </UncontrolledTooltip>
                              <button
                                style={{ border: 0, background: 'transparent' }}
                              >
                                <AccountClockIcon
                                  onClick={() => toggle(c)}
                                  color="#fff"
                                  id="accountClockIcon"
                                  size={18}
                                />
                              </button>
                            </>
                          ) : null}

                          {c.status === 'lead' ||
                          c.status === 'qualified_lead' ||
                          c.status === 'prospect_lead' ||
                          c.status === 'client' ? (
                            <>
                              <UncontrolledTooltip placement="top" target="killIcon">
                                {t('leads.tooltip.kill')}
                              </UncontrolledTooltip>
                              <button
                                style={{ border: 0, background: 'transparent' }}
                              >
                                <AccountOffIcon
                                  color="#fff"
                                  size={18}
                                  id="killIcon"
                                  data-testid="kill-button"
                                  onClick={() => toggleKill(c)}
                                />
                              </button>
                            </>
                          ) : null}
                        </>
                      ),
                      rowCustomClass: { className: c.status },
                    };
                  }),
                }}
              />
            </CardBody>
          </Card>
          {errors ? <div>{errors}</div> : null}
          <div style={{ color: 'red' }}>{mesErrors?.map((r) => r.message)}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default LeadsPage;
