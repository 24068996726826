import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';

import { getEmailTemplatesFetchingActions } from '../../../redux/actions/emailTemplatesActions';
import Loading from '../../../shared/components/Loading';

const EmailTemplates = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { emailTemplates, loading, errors } = useSelector((state) => state.emailTemplates);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmailTemplatesFetchingActions());
  }, [dispatch])

  const renderContent = () => {
    if (loading) {
      return <Loading />;
    }

    if (!emailTemplates || emailTemplates.length === 0) {
      return <p>{t('empty_states.default')}</p>
    }

    return (
      <Card>
        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>{t('email_template.table_subject')}</th>
                <th>{t('email_template.table_actions')}</th>
              </tr>
            </thead>
            <tbody>
              {emailTemplates.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.subject}</td>
                    <td>
                      <Button className="m-0" color="primary" size="sm" outline onClick={() => {
                        navigate(`/settings/email-templates/${item.id}`);
                      }}>
                        {t('email_template.btn_edit')}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }

  return (
    <Container className="email-templates">
      <Row>
        <Col>
          {renderContent()}
          {
            errors ? (<div>{errors}</div>) : null
          }
        </Col>
      </Row>
    </Container>
  );
};

export default EmailTemplates;