import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import { getSingleEmailFetching, updateSingleEmailAction, updateSingleEmailActionFail, updateSingleEmailActionSuccess } from "../../../redux/actions/emailTemplatesActions";
import Loading from '../../../shared/components/Loading';
import Modal from './Modal';

const EditTemplate = () => {
  const { t } = useTranslation('common');
  const params = useParams();
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.auth.user);
  const { singleTemplate, singleLoading, singleErrors, popupError, popupSuccess } = useSelector((state) => state.emailTemplates);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  useEffect(() => {
    dispatch(getSingleEmailFetching(authUser.accessToken, params.id));
  }, [dispatch, authUser.accessToken, params.id]);

  useEffect(() => {
    if (singleTemplate) {
      setSubject(singleTemplate.subject);
      setBody(singleTemplate.body);
    }
  }, [singleTemplate?.subject, singleTemplate?.body,]);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    dispatch(updateSingleEmailAction(singleTemplate.id, subject, singleTemplate.reply_to, body, authUser.accessToken));
  };

  const renderContent = () => {
    if (singleLoading) {
      return <Loading />;
    }

    if (singleErrors) {
      return (
        <Alert color="danger">
          <p><span className="bold-text">Error!</span> {singleErrors}</p>
        </Alert>
      );
    }

    return (
      <Card>
        <CardBody>
          <form className="form" onSubmit={handleSubmit}>
            <Row className="w-100">
              <Col className="col-12">
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('email_template.subject')}:</span>
                  <div className='form__form-group-field'>
                    <input type="text" name='subject' value={subject} onChange={(ev) => {
                      setSubject(ev.target.value)
                    }} />
                  </div>
                </div>
              </Col>
              <Col className="col-12">
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('email_template.body')}:</span>
                  <div className='form__form-group-field'>
                    <textarea style={{ minHeight: '350px' }} type="text" name='body' value={body} onChange={(ev) => {
                      setBody(ev.target.value)
                    }} />
                  </div>
                </div>
              </Col>
              <Col className="col-12">
                <Button className="m-0" color="primary" onClick={handleSubmit}>{t('email_template.btn_save')}</Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    );
  }

  return (
    <Container>
      <Row>
        <Col>{renderContent()}</Col>
        <Modal
          modal={!!popupSuccess}
          toggle={() => {
            dispatch(updateSingleEmailActionSuccess(false));
          }}
          color="success"
          title={t('email_template.success_modal_title')}
          colored
          message={t('email_template.success_modal_message')}
        />
        <Modal
          modal={!!popupError}
          color="danger"
          title={t('email_template.fail_modal_title')}
          colored
          toggle={() => {
            dispatch(updateSingleEmailActionFail(''));
          }}
          message={popupError}
        />
      </Row>
    </Container>
  );
};

export default EditTemplate;