import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '../../../../shared/components/form/FileInput';
import { getLead } from '../../../../utils/api';
import { renderField } from '../../../../utils/helpers';
import { useSelector } from 'react-redux';


const QuoteForm = ({
  modal,
  toggle,
  handleSubmit,
  selectedFile,
  setSelectedFile,
  leadId,
  initialize,
}) => {
  const { t } = useTranslation('common');
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    if (!modal) return;

    getLead(leadId, authUser.accessToken).then((res) => {
      initialize({
        estimate: res?.data?.data?.estimate,
      });
    });
  }, [modal, initialize, leadId, authUser.accessToken]);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName="ltr-support"
      className="modal-dialog--primary theme-light"
    >
      <div className="modal__header">
        <h4 className="text-modal  modal__title">
          {t('leads.quote_form.title_form')}
        </h4>
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
      </div>
      <div className="modal__body">
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('leads.quote_form.title_input_estimate')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="estimate"
                component={renderField}
                type="text"
                placeholder="Estimate"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('leads.quote_form.title_input_comment')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="comment"
                component="textarea"
                type="text"
                placeholder="Comment"
              />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t('leads.quote_form.title_input_comment_file')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="comment_file"
                  value={selectedFile}
                  onChange={(e) => setSelectedFile(e)}
                  component={renderFileInputField}
                />
              </div>
            </div>
          </div>
          <ButtonToolbar className="justify-content-between w-100">
            <Button color="primary" type="submit">
              {t('leads.quote_form.title_button')}
            </Button>
            <Button color="danger" type="button" onClick={toggle}>
              Close
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: 'quote_form',
})(QuoteForm);
