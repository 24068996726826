import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonToolbar, Modal, UncontrolledTooltip } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '../../../../shared/components/form/FileInput';
import { getAllActiveUsersWithStatusClient } from '../../../../utils/api';
import renderSelectField from '../../../../shared/components/form/Select';
import { useSelector } from 'react-redux';
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';

const ContractForm = ({
  modal,
  toggle,
  handleSubmit,
  selectedFile,
  setSelectedFile,
}) => {
  const { t } = useTranslation('common');
  const authUser = useSelector((state) => state.auth.user);
  const [allActiveUsers, setAllActiveUsers] = useState([]);

  useEffect(() => {
    getAllActiveUsersWithStatusClient(authUser.accessToken).then((res) => {
      setAllActiveUsers(res.data.data.users);
    });
  }, [authUser.accessToken]);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName="ltr-support"
      className="modal-dialog--primary theme-light"
    >
      <div className="modal__header">
        <h4 className="text-modal  modal__title">
          {t('leads.contract_form.title_form')}
        </h4>
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
      </div>
      <div className="modal__body">
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('leads.contract_form.title_input_user_id')}
              <HelpCircleOutlineIcon size={"14px"} className={"ml-1"} id="contract_form_select_user" />
              <UncontrolledTooltip placement="top" target="contract_form_select_user">
                If you've already added an email to this lead, simply click CONVERT,
                and I'll create an account for your client. Otherwise,
                set up a new user in the User Management board and select one here.
              </UncontrolledTooltip>
            </span>
            <div className="form__form-group-field">
              <Field
                name="select"
                component={renderSelectField}
                type="text"
                options={allActiveUsers.map((c) => ({
                  value: `${c.first_name} ${c.last_name}`,
                  label: `${c.first_name} ${c.last_name}`,
                  id: c.id,
                }))}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('leads.contract_form.title_input_comment')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="comment"
                component="textarea"
                type="text"
                placeholder="Comment"
              />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t('leads.contract_form.title_input_comment_file')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="comment_file"
                  value={selectedFile}
                  onChange={(e) => setSelectedFile(e)}
                  component={renderFileInputField}
                />
              </div>
            </div>
          </div>
          <ButtonToolbar className="justify-content-between w-100">
            <Button color="primary" type="submit">
              {t('leads.contract_form.title_button')}
            </Button>
            <Button onClick={toggle} color="danger" type="button">
              Close
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: 'contract_form',
})(ContractForm);
