import {
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATES_FETCHING,
  GET_EMAIL_TEMPLATES_FAIL,
  GET_SINGLE_EMAIL_TEMPLATE_FETCHING,
  GET_SINGLE_EMAIL_TEMPLATE_SUCCESS,
  GET_SINGLE_EMAIL_TEMPLATE_FAIL,
  UPDATE_SINGLE_EMAIL_TEMPLATE_FAIL,
  UPDATE_SINGLE_EMAIL_TEMPLATE_SUCCESS,
} from '../actions/emailTemplatesActions';

const initialState = {
  emailTemplates: [],
  loading: false,
  errors: '',
  singleTemplate: null,
  singleLoading: true,
  singleErrors: '',
  popupError: '',
  popupSuccess: false,
};

const emailTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMAIL_TEMPLATES_FETCHING:
      return { ...state, loading: true };
    case GET_EMAIL_TEMPLATES_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: [...action.payload.emailTemplates],
        loading: false,
        errors: '',
      };
    case GET_SINGLE_EMAIL_TEMPLATE_FETCHING:
      return { ...state, singleLoading: true };
    case GET_SINGLE_EMAIL_TEMPLATE_FAIL:
      return {
        ...state,
        singleLoading: false,
        singleErrors: action.payload.error,
      };
    case GET_SINGLE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        singleLoading: false,
        singleErrors: '',
        singleTemplate: action.payload.data,
      };
    case UPDATE_SINGLE_EMAIL_TEMPLATE_FAIL:
      return { ...state, popupError: action.payload.error };
    case UPDATE_SINGLE_EMAIL_TEMPLATE_SUCCESS:
      return { ...state, popupSuccess: action.payload.value };
    default:
      return state;
  }
};

export default emailTemplatesReducer;
