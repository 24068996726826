import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../../../shared/components/form/Select';
import validate from './validate';
import { renderField } from '../../../../../../utils/helpers';
import _ from 'lodash';
import renderDatePickerField from '../../../../../../shared/components/form/DatePicker';

let timerId;

const FormAddReport = ({ handleSubmit, projects, totalTodayHours, queryProjects, setQueryProjects, totalRecordsProjects }) => {
  const arrayMinutes = [
    { value: 0, label: '00' },
    { value: 5, label: '05' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 30, label: '30' },
    { value: 35, label: '35' },
    { value: 40, label: '40' },
    { value: 45, label: '45' },
    { value: 50, label: '50' },
    { value: 55, label: '55' },
    { value: 60, label: '60' }
  ]

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div style={{color: "#29b6f6", padding: "0 0 10px 15px"}}>COMPLETE REPORT DETAILS</div>
          <div style={{color: "#80838e", padding: "0 15px 10px 0"}}>Today reported: {totalTodayHours} hours</div>
        </div>
        <form className='form form-add-invoices' onSubmit={handleSubmit}>
          <Col className="col-12" md={2}>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Project</span>
              <div className='form__form-group-field'>
                <Field
                  name="selectProject"
                  component={renderSelectField}
                  options={_.sortBy(projects, "name")?.map(c => {
                    return {
                      value: c.id,
                      label: c.name
                    }
                  })}
                  onInputChange={(value) => {
                    if(totalRecordsProjects <= 10) return;

                    clearTimeout(timerId);

                    timerId = setTimeout(() => {
                      if (!value) return setQueryProjects({...queryProjects, search_query: ''});

                      setQueryProjects({...queryProjects, search_query: value});
                    }, 600)
                  }}
                />
              </div>
            </div>
          </Col>
          <Col className="col-12" md={2}>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Date</span>
              <div className='form__form-group-field'>
                <Field
                  name='date_report'
                  component={renderDatePickerField}
                />
              </div>
            </div>
          </Col>
          <Col className="col-12" md={4} xxl={6}>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Task Description</span>
              <div className='form__form-group-field d-flex align-items-center'>
                <Field
                  name='task'
                  component={renderField}
                  type='input'
                />
              </div>
            </div>
          </Col>
          <Col className="col-12" md={2} xxl={1}>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Hours</span>
              <div className='form__form-group-field d-flex align-items-center'>
                <Field
                  name='hours'
                  component={renderField}
                  type='input'
                />
              </div>
            </div>
          </Col>
          <Col className="col-12 pl-md-0" md={2} xxl={1}>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Minutes</span>
              <div className='form__form-group-field'>
                <Field
                  name="selectMinutes"
                  component={renderSelectField}
                  options={arrayMinutes?.map(c => {
                    return {
                      value: c.value,
                      label: c.label
                    }
                  })}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} className='d-flex justify-content-end'>
            <button type="submit" className='btn btn-primary'>Save</button>
          </Col>
        </form>
      </CardBody>
    </Card>
  );
};

export default reduxForm({
  form: 'formAddReport',
  initialValues: {
    date_report: new Date(),
    selectMinutes: {value: 0, label: '00'}
  },
  validate
})(FormAddReport);