import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import classNames from 'classnames';

const ModalComponent = ({ color, title, message, colored, header, rtl, modal, toggle }) => {
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName={`${rtl.direction}-support`}
      className={`modal-dialog--${color} ${modalClass}`}
    >
      <div className="modal__header">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
        <h4 className="text-modal  modal__title">{title}</h4>
      </div>
      <div className="modal__body">
        {message}
      </div>
    </Modal>
  );
};

ModalComponent.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: false,
};

export default connect(state => ({
  rtl: state.rtl,
}))(ModalComponent);
