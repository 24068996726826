import {
  getEmailTemplates,
  getSingleEmailTemplate,
  putEmailTemplate,
} from '../../utils/api';

export const GET_EMAIL_TEMPLATES_FETCHING = 'GET_EMAIL_TEMPLATES_FETCHING';
export const GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS';
export const GET_EMAIL_TEMPLATES_FAIL = 'GET_EMAIL_TEMPLATES_FAIL';

export const GET_SINGLE_EMAIL_TEMPLATE_FETCHING =
  'GET_SINGLE_EMAIL_TEMPLATE_FETCHING';
export const GET_SINGLE_EMAIL_TEMPLATE_SUCCESS =
  'GET_SINGLE_EMAIL_TEMPLATE_SUCCESS';
export const GET_SINGLE_EMAIL_TEMPLATE_FAIL = 'GET_SINGLE_EMAIL_TEMPLATE_FAIL';

export const UPDATE_SINGLE_EMAIL_TEMPLATE_SUCCESS =
  'UPDATE_SINGLE_EMAIL_TEMPLATE_SUCCESS';
export const UPDATE_SINGLE_EMAIL_TEMPLATE_FAIL =
  'UPDATE_SINGLE_EMAIL_TEMPLATE_FAIL';

export const getEmailTemplatesFail = (e) => {
  return { type: GET_EMAIL_TEMPLATES_FAIL, payload: { message: e?.message } };
};

export const getEmailTemplatesSuccess = (emailTemplates) => {
  return { type: GET_EMAIL_TEMPLATES_SUCCESS, payload: { emailTemplates } };
};

export const getEmailTemplatesFetchingActions = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: GET_EMAIL_TEMPLATES_FETCHING });
    getEmailTemplates(state.auth.user.accessToken)
      .then(({ data }) => {
        dispatch(getEmailTemplatesSuccess(data.data));
      })
      .catch((e) => {
        dispatch(getEmailTemplatesFail(e));
      });
  };
};

export const getSingleEmailFail = (error) => {
  return { type: GET_SINGLE_EMAIL_TEMPLATE_FAIL, payload: { error } };
};

export const getSingleEmailSuccess = (data) => {
  return { type: GET_SINGLE_EMAIL_TEMPLATE_SUCCESS, payload: { data } };
};

export const getSingleEmailFetching = (accessToken, id) => {
  return (dispatch) => {
    dispatch({ type: GET_SINGLE_EMAIL_TEMPLATE_FETCHING });

    getSingleEmailTemplate(accessToken, id)
      .then(({ data }) => {
        if (data.errors && data.errors.length) {
          throw new Error(data.errors[0]?.message);
        }

        dispatch(getSingleEmailSuccess(data.data[0]));
      })
      .catch((e) => {
        dispatch(getSingleEmailFail(e?.message));
      });
  };
};

export const updateSingleEmailActionFail = (error) => {
  return { type: UPDATE_SINGLE_EMAIL_TEMPLATE_FAIL, payload: { error } };
};

export const updateSingleEmailActionSuccess = (value) => {
  return { type: UPDATE_SINGLE_EMAIL_TEMPLATE_SUCCESS, payload: { value } };
};

export const updateSingleEmailAction = (
  id,
  subject,
  reply_to,
  body,
  accessToken,
) => {
  return (dispatch) => {
    putEmailTemplate(id, subject, reply_to, body, accessToken)
      .then(({ data }) => {
        if (data.errors && data.errors.length) {
          throw new Error(data.errors[0]?.message);
        }

        dispatch(updateSingleEmailActionSuccess(true));
      })
      .catch((e) => {
        console.log(e);

        dispatch(updateSingleEmailActionFail(e?.message));
      });
  };
};
